import {
  UilClipboardAlt,
  UilEnvelope,
  UilLink,
  UilNotes,
  UilPostcard,
  UilRaindrops,
  UilStethoscope,
  UilUsersAlt,
  UilListUl,
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { NavTitle } from './Style';
// import versions from '../demoData/changelog.json';
// import { changeDirectionMode, changeLayoutMode, changeMenuMode } from '../redux/themeLayout/actionCreator';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  // const dispatch = useDispatch();

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : '';
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  // const changeLayout = (mode) => {
  //   dispatch(changeLayoutMode(mode));
  // };

  // const changeNavbar = (topMode) => {
  //   const html = document.querySelector('html');
  //   if (topMode) {
  //     html.classList.add('ninjadash-topmenu');
  //   } else {
  //     html.classList.remove('ninjadash-topmenu');
  //   }
  //   dispatch(changeMenuMode(topMode));
  // };

  // const changeLayoutDirection = (rtlMode) => {
  //   if (rtlMode) {
  //     const html = document.querySelector('html');
  //     html.setAttribute('dir', 'rtl');
  //   } else {
  //     const html = document.querySelector('html');
  //     html.setAttribute('dir', 'ltr');
  //   }
  //   dispatch(changeDirectionMode(rtlMode));
  // };

  // const darkmodeActivated = () => {
  //   document.body.classList.add('dark-mode');
  // };

  // const darkmodeDiactivated = () => {
  //   document.body.classList.remove('dark-mode');
  // };

  const items = [
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('siteManagement')}</NavTitle>,
      'app-template1',
      null,
      null,
      'group',
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/trial`}>
        {t('trial management')}
      </NavLink>,
      'trial',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/trial`}>
          <UilStethoscope />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/trial-entry`}>
        {t('trial entry management')}
      </NavLink>,
      'trial-entry',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/trial-entry`}>
          <UilClipboardAlt />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/member`}>
        {t('member management')}
      </NavLink>,
      'member',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/member`}>
          <UilUsersAlt />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/blood`}>
        {t('blood management')}
      </NavLink>,
      'blood',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/blood`}>
          <UilRaindrops />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/mail-magazine`}>
        {t('mailMagazineManagement')}
      </NavLink>,
      'mailmagazine',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/mail-magazine`}>
          <UilEnvelope />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/survey`}>
        {t('surveyManagement')}
      </NavLink>,
      'survey',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/survey`}>
          <UilListUl />
        </NavLink>
      ),
    ),
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('client')}</NavTitle>,
      'app-template2',
      null,
      null,
      'group',
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/client`}>
        {t('clientManagement')}
      </NavLink>,
      'client',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/client`}>
          <UilPostcard />
        </NavLink>
      ),
    ),
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('others')}</NavTitle>,
      'app-template3',
      null,
      null,
      'group',
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/log`}>
        {t('log management')}
      </NavLink>,
      'log',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/log`}>
          <UilNotes />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/affiliate`}>
        {t('affiliateManagement')}
      </NavLink>,
      'affiliate',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/affiliate`}>
          <UilLink />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/employee`}>
        {t('employee management')}
      </NavLink>,
      'employee',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/employee`}>
          <UilUsersAlt />
        </NavLink>
      ),
    ),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
